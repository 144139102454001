exports.components = {
  "component---src-components-blog-blog-post-js": () => import("./../../../src/components/Blog/BlogPost.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-components-blog-category-js": () => import("./../../../src/components/Blog/Category.js" /* webpackChunkName: "component---src-components-blog-category-js" */),
  "component---src-components-snippet-snippet-post-js": () => import("./../../../src/components/Snippet/SnippetPost.js" /* webpackChunkName: "component---src-components-snippet-snippet-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-goals-js": () => import("./../../../src/pages/goals.js" /* webpackChunkName: "component---src-pages-goals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-projekt-be-great-js": () => import("./../../../src/pages/projekt/be-great.js" /* webpackChunkName: "component---src-pages-projekt-be-great-js" */),
  "component---src-pages-projekt-js": () => import("./../../../src/pages/projekt.js" /* webpackChunkName: "component---src-pages-projekt-js" */),
  "component---src-pages-snippets-js": () => import("./../../../src/pages/snippets.js" /* webpackChunkName: "component---src-pages-snippets-js" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */)
}

